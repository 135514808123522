<template>
  <!-- 预约订单界面 -->
  <div class="page1">
    <div class="content">
      <!-- 搜索条件区域 -->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          label-width="100px"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="车牌号:">
                <el-autocomplete
                  v-model="carName"
                  :fetch-suggestions="querySearchAsyncCreator"
                  placeholder="请输入内容"
                  @select="handleSelectCreator"
                  value-key="plateNumber"
                  :trigger-on-focus="false"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="手机号:" label-width="60px">
                <el-input v-model="formInline.phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="停车场名称:">
                <el-autocomplete
                  v-model="state"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  @select="handleSelect"
                  value-key="parkName"
                  :trigger-on-focus="false"
                ></el-autocomplete>
              </el-form-item>

              <el-form-item label="预约到场时间:">
                <el-date-picker
                  v-model="searchDateS"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="支付方式:">
                <el-select v-model.trim="formInline.payment" filterable>
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="pay.payTypeName"
                    :value="pay.payType"
                    :key="pay.payType"
                    v-for="pay in payTypeList"
                  ></el-option>
                  <!-- <el-option label="全部状态" value></el-option>
                <el-option label="余额支付" value="1"></el-option>
                <el-option label="微信公众号支付" value="3"></el-option>
                <el-option label="微信app支付" value="4"></el-option>
                <el-option label="支付宝app支付" value="5"></el-option>
                <el-option label="微信小程序" value="52"></el-option>-->
                </el-select>
              </el-form-item>
              <el-form-item label="渠道流水号:" label-width="110px">
                <el-input v-model="formInline.payOutTradeNo" placeholder="渠道流水号"></el-input>
              </el-form-item>
              <el-form-item label="原生渠道流水号:" label-width="120px">
                <el-input
                  v-model="formInline.payOutTradeNo"
                  placeholder="原生渠道流水号"
                ></el-input>
              </el-form-item>

              <el-form-item label="支付时间:">
                <el-date-picker
                  v-model="searchDateE"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <el-form-item label="订单状态:">
                <el-select v-model.trim="formInline.orderStatus" filterable>
                  <el-option label="全部状态" value></el-option>
                  <el-option label="进行中" value="0"></el-option>
                  <el-option label="已完成" value="1"></el-option>
                  <el-option label="超时自动取消 " value="2"></el-option>
                  <el-option label="用户手动取消 " value="3"></el-option>
                  <!-- <el-option label="用户放弃预约" value="4"></el-option> -->
                  <el-option label="后台取消" value="5"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData(1)"
                :loading="loading"
                v-if="$route.meta.authority.tabs.finBookOrder.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <!--      -->
              <el-button type="" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.tabs.finBookOrder.button.export"
          ></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                type=""
                @click="exportExcelReport"
                v-if="$route.meta.authority.tabs.finBookOrder.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column prop="payOutTradeNo" :label="$t('searchModule.Channel_serial_number')" align="center">
            <template slot-scope="scope" v-if="scope.row.payOutTradeNo">
              <el-tooltip effect="dark" :content="scope.row.payOutTradeNo" placement="top-start">
                <span v-clipboard:copy="scope.row.payOutTradeNo" v-clipboard:success="onCopy">
                  <el-button type="text" size="small">{{
                    scope.row.payOutTradeNo ? scope.row.payOutTradeNo.slice(0, 5) + "..." : ""
                  }}</el-button>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    command="view"
                    v-if="$route.meta.authority.tabs.finBookOrder.button.view"
                    >查看详情</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    command="update"
                    v-if="
                      scope.row.orderState == '0' &&
                      $route.meta.authority.tabs.finBookOrder.button.edit
                    "
                    >修改</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    command="cancel"
                    v-if="
                      scope.row.orderState == '0' &&
                      $route.meta.authority.tabs.finBookOrder.button.cancel
                    "
                    >取消订单</AuthorityComponent
                  >

                  <!-- <AuthorityComponent ComponentName="el-dropdown-item"
                                      command='up'>上架
                  </AuthorityComponent>
                  <AuthorityComponent ComponentName="el-dropdown-item"
                                      command='down'>下架
                  </AuthorityComponent>-->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <!-- <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleCommand(scope.row)">查看详情</el-button>
              <el-button type="text" size="small" @click="cancelOrder(scope.row)">取消订单</el-button>
            </template>-->
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title :visible.sync="cancleVisible" width="30%" :center="true">
      <el-input type="textarea" placeholder="请输入取消原因" v-model="cancleMessage"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
export default {
  name: "bookingOrder",
  props: ["data"],
  data() {
    return {
      state: "",
      carName: "",
      total: 0,
      pageSize: 15,
      page: 1,
      index: 0,
      loading: false,
      searchDateS: "",
      searchDateE: "",
      payTypeList: [],
      formInline: {
        parkingName: "",
        phone: "",
        parkingNum: "",
        payment: "",
        orderStatus: "",
        parkId: "",
        carId: "",
        payOutTradeNo: "",
      },
      tableData: [
        // {
        //   plateColor: "12345",
        //   mobile: "12345",
        //   parkName: "12345",
        //   appointmentStartTime: "12345",
        //   payMoney: "1",
        //   payType: "1",
        //   payTime: "12345",
        //   orderState: "2",
        //   thirdPartyNo: "12345678901234567890123456",
        // },
      ],
      tableCols: [
        {
          prop: "plateColor",
          label: this.$t("list.plate_number"),
          formatter: (row) => {
            // return row.plateColor + "：" + row.plateNumber;
            return row.plateNumber && row.plateNumber.indexOf("无") != -1
              ? "无牌车"
              : row.plateNumber;
          },
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
        },
        {
          prop: "appointmentStartTime",
          label: "预约到场时间",
        },
        {
          prop: "payMoney",
          label: this.$t("list.Pay_amount"),
          formatter: (row) => {
            if (row.payMoney && row.payMoney != "") {
              return row.payMoney / 100;
            }
          },
        },
        {
          prop: "payTypeName",
          label: "支付方式",
        },
        // {
        //   prop: "payType",
        //   label: "支付方式",
        //   formatter: (row) => {
        //     if (row.payType == 1) {
        //       return "余额支付";
        //     } else if (row.payType == 3) {
        //       return "微信公众号支付";
        //     } else if (row.payType == 4) {
        //       return "微信app支付";
        //     } else if (row.payType == 5) {
        //       return "支付宝app支付";
        //     } else if (row.payType == 52) {
        //       return "微信小程序";
        //     } else {
        //       return "";
        //     }
        //   },
        // },
        {
          prop: "payTime",
          label: "下单时间",
        },
        {
          prop: "orderState",
          label: "订单状态",
          formatter: (row) => {
            const result = [
              "进行中",
              "已完成",
              "超时自动取消",
              "用户手动取消",
              "用户放弃预约",
              "后台取消",
            ];
            return result[row.orderState];
          },
        },
      ],
      cancleVisible: false,
      cancleMessage: "",
      orderIdToBeCanceled: "",
    };
  },
  watch: {
    data: {
      handler(newVal) {
        if (Object.keys(newVal).length > 0) {
          this.formInline.payOutTradeNo = newVal.outTradeNo;
          this.searchDateE = [];
        }
      },
      immediate: true,
    },
    $route: {
      handler(newVal) {
        console.log("af", newVal);
      },
    },
  },
  created() {
    if (this.$route.params.payOutTradeNo) {
      this.formInline.payOutTradeNo = this.$route.params.payOutTradeNo;
    }
    console.log("预约订单权限", this.$route.meta.authority);
  },
  methods: {
    // 取消订单
    // cancelOrder(obj) {
    //   this.cancleVisible = true;
    // },
    resetForm() {
      this.formInline.parkingName = "";
      this.formInline.phone = "";
      this.formInline.parkingNum = "";
      this.formInline.payment = "";
      this.formInline.orderStatus = "";
      this.formInline.parkId = "";
      this.formInline.carId = "";
      this.formInline.payOutTradeNo = "";
      this.carName = "";
      this.searchDateS = [];
      this.searchDateE = [];
      this.state = "";
    },
    confirmSubmit() {
      if (!this.cancleMessage) {
        this.$message("请输入取消订单原因");
        return;
      }
      this.$axios
        .get("/acb/2.0/parkAppointmentOrder/cancel", {
          data: {
            appointmentOrderId: this.orderIdToBeCanceled,
            content: this.cancleMessage,
          },
        })
        .then((res) => {
          this.orderIdToBeCanceled = "";
          if (res.state == 0) {
            this.searchData();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
      this.cancleVisible = false;
      this.cancleMessage = "";
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/systems/loginUser/parkNameList", {
          data: {
            parkTypes: "3,4",
            slaveRelations: "0,2",
            parkName: queryString,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    handleSelect(item) {
      this.formInline.parkId = item.parkId;
      // console.log(item);
    },
    querySearchAsyncCreator(queryString, cb) {
      if (this.carName.length < 3) {
        cb([]);
        return;
      }
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            size: 100,
            part: queryString.toUpperCase(),
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.formInline.bossManagerNameId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    handleSelectCreator(item) {
      this.formInline.carId = item.carId;
      // console.log(item);
    },
    // 获取支付方式
    getPay() {
      let opt = {
        method: "get",
        url: "/acb/2.0/payment/payTypeDictList",
        success: (res) => {
          if (res.state == 0) {
            this.payTypeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searchData(tag) {
      if (tag == 1) {
        this.page = 1;
      }

      // console.log(
      //   "$route.meta.authority.button",
      //   this.$route.meta.authority.button
      // );
      if (this.carName.length < 3) {
        this.formInline.carId = "";
        this.carName = "";
      }
      if (this.state.length < 1) {
        this.formInline.parkId = "";
        this.state = "";
      }

      this.tableData = [];
      this.loading = false;
      // console.log("this.searchDateE == null", this.searchDateE == "");
      // console.log("this.searchDateE == null", this.searchDateE == null);

      // console.log("this.searchDateE == null", this.searchDateS == "");
      // console.log("this.searchDateE == null", this.searchDateS == null);

      // console.log(
      //   "this.searchDateE ",
      //   this.searchDateE == null
      //     ? ""
      //     : dateFormat(this.searchDateE[0], "yyyy-MM-dd") + " 00:00:00"
      // );

      this.$axios
        .get("/acb/2.0/parkAppointmentOrder/findByPage", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            carId: this.formInline.carId,
            mobile: this.formInline.phone,
            parkId: this.formInline.parkId,
            appointmentStartTimeBegin:
              this.searchDateS == null || this.searchDateS == ""
                ? ""
                : dateFormat(this.searchDateS[0], "yyyy-MM-dd") + " 00:00:00",
            appointmentStartTimeEnd:
              this.searchDateS == null || this.searchDateS == ""
                ? ""
                : dateFormat(this.searchDateS[1], "yyyy-MM-dd") + " 23:59:59",
            payType: this.formInline.payment,
            payTimeBegin:
              this.searchDateE == null || this.searchDateE == ""
                ? ""
                : dateFormat(this.searchDateE[0], "yyyy-MM-dd") + " 00:00:00",
            payTimeEnd:
              this.searchDateE == null || this.searchDateE == ""
                ? ""
                : dateFormat(this.searchDateE[1], "yyyy-MM-dd") + " 23:59:59",
            orderState: this.formInline.orderStatus,
            payOutTradeNo: this.formInline.payOutTradeNo,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    indexMethod(index) {
      return index + 1;
    },
    // handleCommand(data) {
    //   // console.log("==data", data);
    //   this.$router.push({
    //     path: "/bookingOrderDetails",
    //     query: data,
    //   });
    // },
    handleCommand(cmd, data) {
      if (cmd == "view") {
        // console.log("==data", data);
        this.$router.push({
          path: "/bookingOrderDetails",
          query: data,
        });
      } else if (cmd == "update") {
        // console.log("==data", data);
        this.$router.push({
          path: "/bookingOrderModify",
          query: data,
        });
      } else if (cmd == "cancel") {
        this.orderIdToBeCanceled = data.appointmentOrderId;
        this.cancleVisible = true;
      }
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },

    onError() {
      this.$alert("复制失败！", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
      });
    },

    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    exportExcelReport() {
      let opt = {
        pageNum: this.page,
        pageSize: this.pageSize,
        carId: this.formInline.carId,
        mobile: this.formInline.phone,
        parkId: this.formInline.parkId,
        appointmentStartTimeBegin:
          this.searchDateS == null || this.searchDateS == ""
            ? ""
            : dateFormat(this.searchDateS[0], "yyyy-MM-dd") + " 00:00:00",
        appointmentStartTimeEnd:
          this.searchDateS == null || this.searchDateS == ""
            ? ""
            : dateFormat(this.searchDateS[1], "yyyy-MM-dd") + " 23:59:59",
        payType: this.formInline.payment,
        payTimeBegin:
          this.searchDateE == null || this.searchDateE == ""
            ? ""
            : dateFormat(this.searchDateE[0], "yyyy-MM-dd") + " 00:00:00",
        payTimeEnd:
          this.searchDateE == null || this.searchDateE == ""
            ? ""
            : dateFormat(this.searchDateE[1], "yyyy-MM-dd") + " 23:59:59",
        orderState: this.formInline.orderStatus,
        payOutTradeNo: this.formInline.payOutTradeNo,
      };
      exportExcelNew("/acb/2.0/parkAppointmentOrder/export", opt, "post");
    },
  },
  components: {},
  mounted() {
    this.getPay();
    this.searchData();
  },
  activated() {
    console.log("权限", this.$route.meta.authority);
    if (this.$route.params.payOutTradeNo) {
      this.formInline.payOutTradeNo = this.$route.params.payOutTradeNo;
      this.$refs.timeRangePicker.resetTime2();
      this.formInline.startTime = "";
      this.formInline.endTime = "";
    }
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}
</style>
<style scoped></style>
